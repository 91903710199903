import type { LoaderFunctionArgs } from '@remix-run/node';

import { json } from '@remix-run/node';
import { Link, useLoaderData } from '@remix-run/react';

import { Button } from '~/components/ui/button';
import { wordpress } from '~/services/wordpress';
import { cleanUpUrl } from '~/utils/cleanup-url';
import { parseHtml } from '~/utils/parse-html';

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const locale = params.locale ?? 'en';
  const { page } = await wordpress.GetPage({ language: locale, slug: 'home' });

  if (!page) throw new Response('Not Found', { status: 404 });

  const guideMenu = await wordpress.GetMenuBySlug({ slug: `guides-${locale}` });
  const pageMenu = await wordpress.GetMenuBySlug({ slug: `pages-${locale}` });

  const guideLinks = guideMenu.menus?.nodes
    .shift()
    ?.menuItems?.nodes.map(({ id, label, path }) => ({
      id,
      label,
      path: cleanUpUrl(locale, path),
    }));

  const pageLinks = pageMenu.menus?.nodes.shift()?.menuItems?.nodes.map(({ id, label, path }) => ({
    id,
    label,
    path: cleanUpUrl(locale, path),
  }));

  return json({
    content: page.content ?? '',
    guideLinks,
    pageLinks,
  });
};

export default function Index() {
  const { content, guideLinks, pageLinks } = useLoaderData<typeof loader>();

  return (
    <div className="prose prose-lg max-w-none prose-blockquote:text-xl prose-li:ml-8 md:prose-blockquote:text-3xl">
      {parseHtml(content)}

      <div className="mx-auto max-w-3xl">
        <div className="grid gap-4 md:grid-cols-2">
          {guideLinks?.map((link) => {
            return (
              <Button key={link.id} size="xl" asChild>
                <Link to={link.path}>{link.label}</Link>
              </Button>
            );
          })}
          {pageLinks?.map((link) => {
            return (
              <Button key={link.id} size="xl" variant="outline" asChild>
                <Link to={link.path}>{link.label}</Link>
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
